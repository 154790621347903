import {memo, useEffect, useRef} from 'react';

import {MetricsSingleton} from 'shared/api/metrics-service';
import {VideoPlayer} from 'shared/components';
import {VIDEO_AD_FINISHED_EVENT, VIDEO_BUFFERING_TIMEOUT_MS} from 'shared/constants';
import {analytics, triggerCustomEvent, isImpressionShouldBeSent} from 'shared/utils';
import {logger as baseLogger} from 'shared/utils/logger';

import type {ParsedResponse} from 'types';

import sendBarkerAnalytics from './helpers';

const logger = baseLogger.child({tag: '[BarkerVideo Component]'});

type BarkerVideoProps = {
  url: string,
  videoConfig: {
    mute?: boolean,
    autoplay?: number,
  },
  barkerData: ParsedResponse['barkerData']
}

const metricsGabrielBarker = MetricsSingleton.getService('Gabriel/Barker');
const metricAnalyticService = MetricsSingleton.getService('Gabriel/AdAnalytics');
export const BarkerVideo = memo(({url, videoConfig, barkerData}: BarkerVideoProps) => {
  const {channelId, blockId, episodeId} = barkerData;

  const isVideoReadyToPlay = useRef(false);
  const durationRef = useRef(0);
  const analyticState = useRef({impressionSent: false});

  /**
   * Trigger analytic error
   */
  const triggerAnalyticError = () => {
    if (!analyticState.current.impressionSent) {
      analytics.emitAdEvent('Error', {
        ad_error_code: `error`,
        ad_error_log_response: 'error while loading barker',
        ad_error_source: 'elemental',
      });
      metricAnalyticService.emitEvent('AdError', [{Name: 'type', Value: 'BarkerChannel'}]);
    }
  };

  // Ported this from Gabriel V1 to detect if an ad is taking too long to buffer.
  // This is temporary until we find a better way to handle it. - Juan.
  useEffect(() => {
    isVideoReadyToPlay.current = false;
    analyticState.current.impressionSent = false;
    metricAnalyticService.emitEvent('AdRequest', [{Name: 'type', Value: 'BarkerChannel'}]);
    const videoBufferTimeout = setTimeout(() => {
      if (!isVideoReadyToPlay.current) {
        logger.warn(`Video buffering timed out. Forcing VIDEO_AD_FINISHED_EVENT event: [${url}]`);
        triggerAnalyticError();
        triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: true});
      }
    }, VIDEO_BUFFERING_TIMEOUT_MS);

    return () => clearTimeout(videoBufferTimeout);
  }, [url]);

  return (
    <VideoPlayer
      className='default-video-container'
      url={url}
      playing
      muted={!!videoConfig.mute}
      onStart={() => {
        analytics.emitAdEvent('AdBarkerData', {
          channel_id: channelId,
          episode_id: episodeId,
          block_id: blockId,
          eventType: 'start',
        });
        metricsGabrielBarker.emitEvent('EpisodeClipPlayback', [{Name: 'position', Value: 'start'}]);
      }}
      onReady={() => {
        isVideoReadyToPlay.current = true;
      }}
      onEnded={() => {
        analytics.emitAdEvent('AdBarkerData', {
          channel_id: channelId,
          episode_id: episodeId,
          block_id: blockId,
          eventType: 'complete',
        });
        metricsGabrielBarker.emitEvent('EpisodeClipPlayback', [{Name: 'position', Value: 'finish'}]);
        triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: false});
      }}
      onDuration={(duration) => {
        durationRef.current = duration;
      }}
      onProgress={(progress) => {
        const playedSeconds = Math.trunc(progress.playedSeconds);

        sendBarkerAnalytics(playedSeconds, durationRef.current, (eventType) => {
          metricsGabrielBarker.emitEvent('EpisodeClipPlayback', [{Name: 'position', Value: eventType}]);
          analytics.emitAdEvent('AdBarkerData', {
            channel_id: channelId,
            episode_id: episodeId,
            block_id: blockId,
            eventType: eventType,
          });

          if (isImpressionShouldBeSent(analyticState.current.impressionSent, eventType)) {
            metricAnalyticService.emitEvent('AdImpression', [
              {Name: 'type', Value: 'BarkerChannel'},
            ]);
          }
        });
      }}
      onError={() => {
        triggerAnalyticError();
        triggerCustomEvent(VIDEO_AD_FINISHED_EVENT, {isError: true});
      }}
    />
  );
});
