import axios from 'axios';

import {meteEnvConfig} from 'config';
import {MetricsSingleton} from 'shared/api/metrics-service/index.ts';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';
import {logger as baseLogger} from 'shared/utils/logger';

import {baseUrl} from './constants.ts';

import type {AxiosInstance, AxiosResponse, HttpStatusCode} from 'axios';
import type {FlagSet} from 'shared/utils/launch-darkly-service/types';

const logger = baseLogger.child({tag: '[Transcoding Service]'});
const metricsGabrielVideo = MetricsSingleton.getService('Gabriel/Video');

/**
 * Singleton service for managing video transcoding requests.
 */
class TranscodingService {
  private static instance: TranscodingService;
  private httpInstance: AxiosInstance;
  private static ldServiceFlags: FlagSet;

  /**
   * Private constructor to enforce singleton pattern.
   */
  private constructor() {
    this.httpInstance = axios.create({
      baseURL: baseUrl,
      timeout: 1000,
    });
  }

  /**
   * Retrieves the singleton instance of the TranscodingService.
   * @return {TranscodingService} The singleton instance of the service.
   */
  public static getInstance(): TranscodingService {
    this.ldServiceFlags = LaunchDarklyService.getFlags();

    if (!TranscodingService.instance) {
      TranscodingService.instance = new TranscodingService();
    }
    return TranscodingService.instance;
  }

  /**
   * Sends a request to transcode the given video and returns the URL of the transcoded video.
   *
   * @param {string | null} videoURL - The URL of the video to be transcoded. If `null`, the method returns `null`.
   * @return {Promise<string | null>} - A promise that resolves to the URL of the transcoded video, or `null` if `videoURL` is `null`.
   * @throws {Error} - If the request fails, an error is thrown with a message.
   */
  public async getTranscodedVideoUrl(videoURL: string | null ): Promise<string | null> {
    if (!videoURL) {
      return null;
    }
    if (TranscodingService.ldServiceFlags['video-component-transcoding-status'] === 'disabled') {
      logger.warn('Transcoding request skipped because the service is disabled.');
      return videoURL; // Return the original video URL without transcoding
    }
    const preset = meteEnvConfig.ads.adUnit === 'ADAM_SCREENSAVER_VIDEO_UNIT'
    ? 'adam720p' as const
    : 'eve360p' as const;

    const requestPayload = {videoURL, preset};

    try {
      const response = await this.post<typeof requestPayload, {videoURL: string}>('/transcode', requestPayload);

      return response.data.videoURL;
    } catch (error) {
      metricsGabrielVideo.emitEvent('FailedTranscodingRequest', [{Name: 'Endpoint', Value: 'transcode'}]);
      logger.warn(`Failed to transcode video: ${error.message}`);
      return videoURL;
    }
  }

  /**
 * Sends a request to fetch the Closed Captions (CC) file URL for the given video URL.
 *
 * @param {string | null} videoUrl - The URL of the video for which to fetch the Closed Captions file. If `null`, the method returns `null`.
 * @return {Promise<string | undefined>} - A promise that resolves to the URL of the Closed Captions file, or `null` if the videoURL is `null` or if no Closed Captions are available.
 * @throws {Error} - If the request fails, an error is thrown with a message.
 */
  public async fetchClosedCaptionsUrl(
    videoUrl: string | undefined,
  ): Promise<{subtitles: string | undefined, status: HttpStatusCode} | undefined> {
    if (!videoUrl || TranscodingService.ldServiceFlags.closedCaptionsStatus === 'disabled') return undefined;

    const requestPayload = {videoURL: videoUrl};
    try {
      const response = await this.post<typeof requestPayload, {subtitles: string} | undefined>(
        '/subtitles',
        requestPayload,
      );

      return {subtitles: response.data?.subtitles, status: response.status};
    } catch (error) {
      metricsGabrielVideo.emitEvent('FailedTranscodingRequest', [{Name: 'Endpoint', Value: 'subtitles'}]);
      logger.warn(`Failed to load Closed Captions: ${error.message}`);
      return undefined;
    }
  }

  /**
    * Sends a POST request with a generic request payload and returns a generic response.
    *
    * @param {string} url - The endpoint URL for the POST request.
    * @param {T} data - The request payload.
    * @return {Promise<AxiosResponse<R>>} - A promise that resolves to the full Axios response object of type `R`.
    */
  private async post<T, R>(url: string, data: T): Promise<AxiosResponse<R>> {
    const response: AxiosResponse<R, T> = await this.httpInstance.post<R>(url, data);
    return response;
  }
}

export {TranscodingService};
