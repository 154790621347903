import {useEffect, useRef, useState} from 'react';

import {gabrielLifecycleMetrics} from 'shared/api/metrics-service';
import {GABRIEL_READY_EVENT, TELLY_EVENT} from 'shared/constants';
import {analytics, sendEventToParent} from 'shared/utils';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import type {DeviceInfo} from 'types';

import {validateDeviceProperties} from './validation-schema';

const logger = baseLogger.child({tag: '[Device Properties Manager]'});

/**
 * Custom hook that manages and provides device properties.
 *
 * @return {Object} An object containing the current device properties.
 * @property {DeviceInfo | null} deviceProperties - The current device properties or `null` if unavailable.
 *
 * @example
 * // Usage of the useDeviceProperties hook
 * const { deviceProperties } = useDeviceProperties();
 * console.log('Current device properties:', deviceProperties);
 */
export default function useDeviceProperties(): { deviceProperties: DeviceInfo | null } {
  const [deviceProperties, setDeviceProperties] = useState<DeviceInfo | null>(
    null,
  );
  const devicePropertiesRef = useRef<DeviceInfo | null>(null);
  const sdkEvent = useRef(new AndroidSDKEvent());
  const isFirstRender = useRef(true);

  useEffect(() => {
    const handleData = (event: Event): void => {
      logger.info('Handling custom data event from parent', event);

      const customEvent = event as unknown as { detail: Record<string, unknown> };

      if (customEvent.detail?.env != null) {
        const properties = (event as unknown as { detail: DeviceInfo }).detail;

        if (isFirstRender.current) {
          setDeviceProperties(properties);
          isFirstRender.current = false;
          if (properties !== null) {
            gabrielLifecycleMetrics.emitEvent('DevicePropertyEventReceived');
          }
        } else {
          devicePropertiesRef.current = properties;
          gabrielLifecycleMetrics.emitEvent('DevicePropertyEventReceived');
        }
        localStorage?.setItem('device-properties', JSON.stringify(properties));
        logger.info('Updated device properties', properties);
        validateDeviceProperties(properties);
      }
    };

    const handleTellyEvent = (): void => {
      sdkEvent.current.gabrielIsReady();
      sendEventToParent(GABRIEL_READY_EVENT);

      const storedDeviceProperties = localStorage?.getItem('device-properties');
      if (storedDeviceProperties) {
        try {
          devicePropertiesRef.current = JSON.parse(storedDeviceProperties);
          isFirstRender.current = false;
          setDeviceProperties(devicePropertiesRef.current);
          validateDeviceProperties(JSON.parse(storedDeviceProperties));
          gabrielLifecycleMetrics.emitEvent('DevicePropertyEventReceived');
        } catch (error) {
          logger.error('Error parsing stored device properties', error);
          devicePropertiesRef.current = null;
          setDeviceProperties(null);
        }
      }
    };

    window.addEventListener(TELLY_EVENT, handleData);
    handleTellyEvent();

    return () => {
      window.removeEventListener(TELLY_EVENT, handleData);
    };
  }, []);

  const currentDeviceProperties = devicePropertiesRef.current ?? deviceProperties;

  // Set Debug Information
  window.gabriel = {
    ...window.gabriel,
    deviceInfo: currentDeviceProperties,
  };

  analytics.setActionsContext({ifa: currentDeviceProperties?.ad_info?.ifa});
  return {deviceProperties: currentDeviceProperties};
}
