import React, {useRef} from 'react';

import {QuickActionButton} from 'shared/components';
import {useHeartBeat} from 'shared/hooks';
import {cn} from 'shared/utils';
import freestarManager from 'shared/utils/freestar-ads';
import {logger as baseLogger} from 'shared/utils/logger';

import {useFreestar} from './useFreestar';

import type {ExtResponseAdConfig} from 'features/adoppler';

import './index.css';

const logger = baseLogger.child({tag: '[Freestar Component]'});
const houseCreativeType = {
  ad_conversion_target_display: 'THEATER',
  ad_component_type: 'full',
  ad_conversion_trigger: 'b_button',
  ad_creative_type_name: 'eve_house_ad_qab',
} satisfies ExtResponseAdConfig['creative_type'];

const FreestarComponent = ({show}: {show: boolean}) => {
  const {size: adSizeClassName, defaultQabElement, crid} = useFreestar();
  const adNode = useRef<HTMLDivElement>(null);

  const isHouseAd = adSizeClassName === 'house-ad-rectangle';

  useHeartBeat(show, 'freestar');

  logger.info('Render with size', adSizeClassName, crid);

  if (show && crid && adSizeClassName) {
    setTimeout(() => freestarManager.triggerAnalytic(), 1);
    setTimeout(() => freestarManager.triggerMetric(), 1);
  }

  return (
    <>
      <div
        ref={adNode}
        id='native-ad-fs'
        data-testid="telly-freestar-ad"
        className={
          cn(
            show
            ? (adSizeClassName ?? 'native-gpt')
            : 'hidden-ad',
          )
        }
      />
      <QuickActionButton
        defaultElement={defaultQabElement}
        adClickTracker={{clicktrackers: [], url: ''}}
        displayControlsBlock={
          show && (adSizeClassName === 'rectangle' || adSizeClassName === 'prebid-size')
        }
        crid={crid}
        ext={{creative_type: isHouseAd ? houseCreativeType : undefined}}
        isClickable={show && (isHouseAd || defaultQabElement === 'button-b')}
        adNode={adNode}
      />
    </>
  );
};

const Freestar = React.memo(FreestarComponent);

export default Freestar;
