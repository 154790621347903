import axios from 'axios';
import {v4 as uuid} from 'uuid';

import {AdTypes, type AdopplerResponse} from 'features/adoppler';
import {getImpressionByType} from 'shared/api/ad/adoppler/helpers';
import {type MetricsService, gabrielElementalMetrics} from 'shared/api/metrics-service';
import {ACR_AD_RECEIVED_EVENT, ACR_TELLY_SDK_EVENT} from 'shared/constants';
import {logger as baseLogger, eventEmitter} from 'shared/utils';

import {validateAcrResponse} from './validation-schema';

import type {ACRMatchResponse, ACRResponse, TypeRequestParams} from './types';
import type {AxiosResponse} from 'axios';

const logger = baseLogger.child({tag: '[AcrService]'});

/**
 * Service class for handling Automatic Content Recognition (ACR) events and making API requests.
 */
class AcrService {
  previousEvent: string | null = null;
  requestParams: TypeRequestParams | null = null;
  url: string | null = null;
  handleAcrEvent: (event: Event) => void;
  ignoreAcrRequest = false;
  metrics: MetricsService;

  /**
   * constructor
   */
  constructor() {
    this.metrics = gabrielElementalMetrics;
    this.handleAcrEvent = (event: Event) => {
      try {
        const data = (event as CustomEvent).detail;
        logger.info('Handle acr event', event, data);
        const parsedData = JSON.parse(data);
        if (this.isDiffFromPrevEvent(parsedData) && !this.ignoreAcrRequest) {
          this.makeRequest(parsedData).catch();
        }
      } catch (e) {
        logger.error('Cannot parse acr params', e);
      }
    };
  }

  /**
   * Checks if the provided ACR response data is different from the previous event data.
   * Stringifies the payload of the ACR response and compares it with the previously stored event data.
   * Updates the `previousEvent` property with the stringified payload.
   *
   * @param {ACRResponse} parsedData - The ACR response data.
   * @return {boolean} True if the current event data is different from the previous event data, false otherwise.
   */
  isDiffFromPrevEvent(parsedData: ACRResponse): boolean {
    const stringifiedPayload = JSON.stringify((parsedData as ACRMatchResponse)?.payload);
    if (stringifiedPayload !== this.previousEvent) {
      this.previousEvent = stringifiedPayload;
      return true;
    }
    return false;
  }

  /**
   * Listens for the 'acr' event on the window object.
   * When the event is triggered, checks if the event data is different from the previous event.
   * If different, calls the makeRequest method with the new event data.
   */
  listenForEvent() {
    window.addEventListener(ACR_TELLY_SDK_EVENT, this.handleAcrEvent);
  }

  /**
   * Removes the 'acr' event listener from the window object.
   */
  removeListeners() {
    window.removeEventListener(ACR_TELLY_SDK_EVENT, this.handleAcrEvent);
  }

  /**
   * Makes a POST request to the API endpoint with the provided ACR response data.
   * Constructs the request body based on the requestParams and the provided ACR response.
   * If the request is successful (status code 200), calls the dispatchSuccessEvent method with the response data.
   *
   * @param {ACRResponse} acrResponse - The ACR response data.
   */
  async makeRequest(acrResponse: ACRResponse) {
    if (!this.requestParams || !this.url) {
      return;
    }
    const {validAcrResponse} = validateAcrResponse(acrResponse);
    if (!validAcrResponse.length) {
      logger.info('No required data in acr events');
      return;
    }

    try {
      logger.info('Try to make request', validAcrResponse);
      const url = this.url;
      const requestParams = this.requestParams;
      const requests = validAcrResponse.map((validAcr) => new Promise<AxiosResponse>((resolve, reject) => {
        this.metrics.emitEvent('Request', [{Name: 'source', Value: 'acr'},
          {Name: 'type', Value: AdTypes.MultiBid.toString()}]);

        axios.post(url, {
          ...requestParams,
          id: uuid(),
          imp: getImpressionByType(AdTypes.MultiBid),
          app: {
            ...requestParams.app,
            content: {
              genre: validAcr.typeMetadata?.brandId,
              title: validAcr.id,
            },
          },
        }).then((response) => {
          this.metrics.emitEvent('Response', [{Name: 'source', Value: 'acr'},
            {Name: 'type', Value: AdTypes.MultiBid.toString()},
            {Name: 'code', Value: response.status.toString()}]);

          return response.status === 200 ? resolve(response) : reject(new Error('Unsuccessfull HTTP status'));
        }).catch((e) => {
          logger.warn('axios request failed', e);
          reject(e);
        });
      }));
      const response = await Promise.any(requests);
      logger.info('Response', response);

      if (response) {
        this.dispatchSuccessEvent(response.data);
      }
    } catch (error) {
      logger.warn('Request failed', error);
    }
  }

  /**
   * Dispatches a custom event 'acr_ad' with the provided response data.
   *
   * @param {AdopplerResponse} data - The response data from the API.
   */
  dispatchSuccessEvent(data: AdopplerResponse) {
    logger.info('trigger successful response', data);
    eventEmitter.emit(ACR_AD_RECEIVED_EVENT, data);
  }

  /**
   * Sets the request parameters and API endpoint URL for the service.
   *
   * @param {string} url - The API endpoint URL.
   * @param {TypeRequestParams} requestParams - The request parameters.
   */
  setRequestParams(url: string, requestParams: TypeRequestParams) {
    this.requestParams = requestParams;
    this.url = url;
  }
}

export const acrService = new AcrService();
