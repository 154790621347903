import {type MetricsService, gabrielElementalMetrics, gabrielCacheMetrics} from 'shared/api/metrics-service';
import {eventEmitter} from 'shared/utils';

import type {WorkerConfig, WorkerParams} from 'types';

/**
 * Define default web-worker for fetching Adoppler source
 */
class FetchWorker {
  static SCRIPT = './adoppler.js';
  config: WorkerConfig;
  instance: Worker;
  metrics: MetricsService;
  cacheMetrics: MetricsService;

  /**
   * Make worker instance
   * @param {WorkerConfig} config
   */
  constructor(config: WorkerConfig) {
    this.config = config;
    this.instance = this.register(this.config.scope);
    this.metrics = gabrielElementalMetrics;
    this.cacheMetrics = gabrielCacheMetrics;

    console.log(`Worker [${this.config.scope}] registered`);
    // set error handler
    this.instance.onerror = (e) => {
      console.error(`Worker [${this.config.scope}] throws an error ${e.toString()}`);
    };

    this.instance.onmessage = (e) => {
      const message = e.data;
      switch (message.action.toLowerCase()) {
        case ('adrequest'):
          this.metrics.emitEvent('Request', [{Name: 'source', Value: 'regular'},
            {Name: 'type', Value: config.adType.toString()}]);
          break;

        case ('adresponseerror'):
        case ('adresponse'):
          this.metrics.emitEvent('Response', [{Name: 'source', Value: 'regular'},
            {Name: 'type', Value: config.adType.toString()},
            {Name: 'code', Value: message.payload.data.statusCode.toString()}]);
          break;

        case ('addtocache'):
          eventEmitter.emit(`${message.action}-${message.payload.cacheKey}`);
          this.cacheMetrics.emitEvent(message.action, [{Name: 'provider', Value: 'Elemental'},
            {Name: 'namespace', Value: message.payload.namespace.toString()}]);
          break;
        case ('expiredcache'):
          if (message.payload && 'namespace' in message.payload) {
            this.cacheMetrics.emitEvent(message.action, [{Name: 'provider', Value: 'Elemental'},
              {Name: 'namespace', Value: message.payload.namespace.toString()}]);
          } else {
            console.warn(`Worker [${this.config.scope}] missed data in object ${JSON.stringify(message)}`);
          }
          break;
      }
    };
  }

  /**
   * Run worker instance
   * @param {object} params
   */
  run(params: WorkerParams) {
    this.instance.postMessage(JSON.stringify({action: 'config', payload: {
      url: this.config.url,
      cache: this.config.cache,
      scope: this.config.scope,
    } }));
    this.instance.postMessage(JSON.stringify({action: 'body', payload: params}));
    this.instance.postMessage(JSON.stringify({action: 'run', payload: null}));
  }

  /**
   * Register new worker for the scope
   * @param {string} scope
   * @return {Worker}
   */
  register(scope: string): Worker {
    return new Worker(FetchWorker.SCRIPT, {
      name: scope,
    });
  }

  /**
   * terminate worker
   */
  terminate() {
    this.instance.terminate();
  }
}

export default FetchWorker;
