import {useEffect, useState} from 'react';

import QRCodeGenerator from 'shared/utils/qr-code-generator';

interface Props {
  url: string;
}

const QrCodeComponent = ({url}: Props) => {
  const [qrCode, setQrCode] = useState<string | null>(null);

  useEffect(() => {
    const constructQr = async () => {
      try {
        const qrGenerator = new QRCodeGenerator();
        const dataUrl = await qrGenerator.getQRCodeAsBase64(url);
        setQrCode(dataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    constructQr();
  }, [url]);

  if (!qrCode) return null;

  return (
    <div data-testid="qr-code-component" className='qr-container'>
      <img src={qrCode} alt="qr-code" className='qr-image'/>
      <div className="qr-text">
        <span>Scan</span>
      </div>
    </div>
  );
};

export default QrCodeComponent;
