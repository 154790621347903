import type {RefObject} from 'react';

import AnimatedQuickActionComponent from './AnimatedQuickActionComponent';
import QrCodeComponent from './QrCodeComponent';

import type {QabElement, ExtResponseAdConfig} from 'features/adoppler';
import type {NativeAd} from 'features/adoppler/types/native';

interface Props {
    adClickTracker: NativeAd.Link | null;
    ext: ExtResponseAdConfig | undefined;
    defaultElement?: QabElement;
    crid?: string | undefined;
    displayControlsBlock?: boolean;
    adNode?: RefObject<HTMLElement>;
    isClickable?: boolean;
  }

const V2QuickActionButtonRender = (props: Props) => {
  const {ext, displayControlsBlock, defaultElement = 'default-ad', crid, adClickTracker} = props;
  const {action} = ext?.creative_type ?? {};
  const {params, type} = action ?? {};
  const shouldRenderButton = type === 'quark_url' || type === 'deeplink' || type === 'open_url';
  const shouldRenderQr = type === 'qr';

  // Default ad element
  const defaultAd =
        <div className={'empty-ad extended-ad'}
          style={{
            display: displayControlsBlock ? 'flex' : 'none',
          }}>
          <p>Ad</p>
        </div>;

  if (!shouldRenderQr && !displayControlsBlock) {
    return null;
  }

  if (shouldRenderQr && adClickTracker?.url) {
    if (!params?.isRequiredGenerateImage) return defaultAd;
    else return <QrCodeComponent url={adClickTracker.url}/>;
  }

  if (params && shouldRenderButton) {
    const buttonType = params?.trigger === 'a_button' ? 'a_button' : 'b_button';
    const screenType = (type === 'quark_url')? params.display : params.screen;
    return <AnimatedQuickActionComponent crid={crid} buttonType={buttonType} screen={screenType} />;
  }

  // Rendering by defined default element.
  if (!ext?.creative_type) {
    if (defaultElement === 'default-ad') {
      return defaultAd;
    } else if (defaultElement === 'button-b') {
      let screenType = ext?.creative_type?.ad_conversion_target_display;
      if (!screenType || !['THEATER', 'SMART'].includes(screenType)) {
        screenType = 'THEATER';
      }
      return displayControlsBlock && <AnimatedQuickActionComponent crid={crid} buttonType={'b_button'}
        screen={screenType} />;
    }
  }

  return null;
};

export default V2QuickActionButtonRender;
